
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import TypeForm from './components/type-form.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { apiShopTypeDel, apiShopTypeLists, apiShopTypeDetail, apiShopTypeAdd } from '@/api/shop'
@Component({
    components: {
        LsDialog,
        LsPagination,
        TypeForm
    }
})
export default class Category extends Vue {
    $refs!: { plTreeTable: any }
    pager = new RequestPaging()

    formData = {
        name: ''
    }

    getList(): void {
        this.pager
            .request({
                callback: apiShopTypeLists,
                params: this.formData
            })
            .then(res => { })
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.formData).map((key) => {
            this.$set(this.formData, key, '')
        })
        this.getList()
    }

    handleDelete(id: number) {
        apiShopTypeDel({id}).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiShopTypeDetail, apiShopTypeAdd, apiShopTypeEdit, apiShopTypeLists } from '@/api/shop'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog
    }
})
export default class TypeForm extends Vue {
    $refs!: { popupRef: any, formDataRef: any };
    @Prop() btnText: any
    @Prop() id: any
    @Prop() type!: any

    categoryList: any = []

    formData = {
        name: '',
        pid: '',
        is_parent: 0,
        sort: ''
    }

    rules = {
        name: [
            { required: true, message: '请输入门店类型', trigger: 'blur' }
        ],
    }

    getCategoryLists() {
        apiShopTypeLists({ page_size: 100 }).then(res => {
            this.categoryList = res.lists
        })
    }

    handleOpen() {
        this.getCategoryLists();
        if (this.id) this.initShopTypeDetail()
    }

    handleClose() {
        this.$refs.formDataRef.resetFields()
    }

    // 添加门店分类
    handleShopTypeAdd() {
        // if (!this.formData.is_parent) {
        //     this.formData.pid = ''
        // }
        apiShopTypeAdd({ ...this.formData }).then(res => {
            this.$emit('refresh')
        })
    }

    // 编辑门店分类
    handleShopTypeEdit() {
        apiShopTypeEdit({ ...this.formData, id: this.id }).then(res => {
            this.$emit('refresh')
        })
    }

    onSubmit() {
        const refs = this.$refs.formDataRef
        refs.validate((valid: boolean): void => {
            if (!valid) {
                return
            }

            if (this.id) {
                this.handleShopTypeEdit()
            } else {
                this.handleShopTypeAdd()
            }
            this.$refs.popupRef.close()
        })
    }

    initShopTypeDetail() {
        apiShopTypeDetail({ id: this.id }).then(res => {
            this.formData = res;
        })
    }
}
